<template>
  <div>
    <div class="container mt-4 mb-7 mt-md-12 mb-md-15 py-0">
      <Title
        :title="$t('Footer.HelpCenter')"
      />
      <div class="mt-5 mt-md-6 mt-lg-8">
        <ContainerInfoCards :cards="cards" :sectionTitle="$t('HelpCenter.Desc')" />
      </div>
        <MoreHelp />
    </div>
  </div>
</template>

<script>
import ContainerInfoCards from "@/components/global/containers/ContainerInfoCards.vue";
import Title from "@/components/global/headings/Title.vue";
import MoreHelp from "@/components/global/MoreHelp.vue";

export default {
  components: {
    ContainerInfoCards,
    Title,
    MoreHelp,
  },
  data() {
    return {
      cards: [
        {
          title: this.$t("Footer.Footer.Buyers"),
          link: {
            name: "HelpCenterSubsection",
            params: {
              subsection: this.$t("Footer.Footer.Buyers").toLowerCase(),
            },
          },
        },
        {
            title: this.$t("Footer.Footer.Seelers"),
            link: {
                name: 'HelpCenterSubsection',
                params: {
                    subsection: this.$t("Footer.Footer.Seelers").toLowerCase()
                }
            }
        },
        {
            title: this.$t("WorkWithUs.InspectionAgents"),
            link: {
                name: 'HelpCenterSubsection',
                params: {
                    subsection: this.$t("WorkWithUs.InspectionAgents").replaceAll(' ', '-').toLowerCase()
                }
            }
        },
        {
            title: this.$t("WorkWithUs.CustomsBrokers"),
            link: {
                name: 'HelpCenterSubsection',
                params: {
                    subsection: this.$t("WorkWithUs.CustomsBrokers").replaceAll(' ', '-').toLowerCase()
                }
            }
        },
        {
            title: this.$t("navBar.label.salesRepresentatives"),
            link: {
                name: 'HelpCenterSubsection',
                params: {
                    subsection: this.$t('navBar.link.SalesRepresentatives').toLowerCase()
                }
            }
        },
        {
            title: this.$t("WorkWithUs.FreightForwarders"),
            link: {
                name: 'HelpCenterSubsection',
                params: {
                    subsection: this.$t("WorkWithUs.FreightForwarders").replaceAll(' ', '-').toLowerCase()
                }
            }
        },
      ],
    };
  },
};
</script>

<style></style>
