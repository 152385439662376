<template>
        <h1
        class="short-border-bottom mb-sm-4 mb-md-5 mb-lg-7 mb-2 fs-34 fw-600"
        v-html="title"
      >
      </h1>
</template>

<script>
export default {
    props: {
    title: {
      type: String,
      required: true,
    },
    }
}
</script>

<style lang="scss" scoped>
.short-border-bottom {
  &:after {
    content: "";
    margin-top: 0.4rem;
    display: block;
    width: 50px;
    border-bottom: 7px solid $yellow;
  }
}
</style>