<template>
        <h6 class="mb-0 fw-600 fs-24" v-html="sectionTitle"></h6>
</template>

<script>
export default {
    props: {
    sectionTitle: {
        type: String,
        required: true
    }
    }
}
</script>

<style>

</style>