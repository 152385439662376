<template>
  <div>
    <SectionTitle v-if="sectionTitle" :sectionTitle="sectionTitle" class="mb-3 mb-md-4 mb-lg-5"/>
    <div class="d-flex gap-2 flex-row-custom col-12 row mx-0 p-0">
      <router-link
      :to="card.link ? card.link : '#'"
      v-for="card in cards"
      :key="card.title"
      class="d-flex col col-xs-12 col-md-6-custom col-lg-4 p-3 bg-white border rounded col-maxw text-dark"
    >
      <div
        class="d-flex w-100"
        :class="
          !card.desc
          ? 'flex-row justify-content-between align-items-center'
            : 'flex-column'
            "
        style="border-color:#D0D0D0;"
        >
        <h6
          class="family-inter text-primary fs-18 fw-600 mb-0"
        >
          {{ card.title[0].toUpperCase() + card.title.slice(1, card.title.length).toLowerCase() }}
        </h6>
        <p class="mt-1 mt-sm-2 mt-md-3" v-if="card.desc">{{ card.desc }}</p>
        <div v-else>
          <ChevronRight class="text-primary" />
        </div>
      </div>
    </router-link>
  </div>
</div>
</template>

<script>
import ChevronRight from "vue-material-design-icons/ChevronRight.vue";
import SectionTitle from "@/components/global/headings/SectionTitle.vue"

export default {
  components: {
    ChevronRight,
    SectionTitle
  },
  props: {
    containerType: {
      type: Number,
      required: false,
      default: 0,
    },
    cards: {
      type: Array,
      required: true,
    },
    sectionTitle: {
      type: String,
      required: false
    }
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin:0;
}

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
.flex-row-custom {
  flex-direction: column !important;
  @media (min-width: 768px) {
    flex-direction: row !important;
  }
}

.col-md-6-custom {
    @media screen and (min-width:992px) {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.col-maxw {
  @media (min-width: 768px) {
    min-width: calc(50% - 10px);
  }
  @media (min-width: 992px) {
    min-width: unset;
    max-width: calc(33% - 7.5px);;
  }
}
</style>
