<template>
    <div
      class="bg-primary rounded p-3 py-7 text-white d-flex justify-content-between align-items-center shadow-sm mt-8"
      @click="openModal"
      style="cursor: pointer;"
    >
      <div>
        <h6 class="fs-20 fw-600">{{ $t("global.message.NeedMoreHelp") }}</h6>
        <p class="mb-0 fw-400 family-inter">
          {{ $t("global.message.ContactDedicatedTeam") }}
        </p>
      </div>
      <div class="rounded-circle bg-white d-flex justify-content-center p-2">
        <ChevronRight :size="30" class="text-primary" />
      </div>
    </div>
</template>

<script>
import ChevronRight from "vue-material-design-icons/ChevronRight.vue";
import { mapActions } from "vuex";

export default {
  components: {
    ChevronRight,
  },
  methods: {
    ...mapActions(['setCustomerServiceModal']),
    openModal() {
      this.setCustomerServiceModal(true)
    }
  }
};
</script>

<style></style>
